import React, { useRef, useState, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { useDrag } from "react-use-gesture";
import { ScreenContext } from "@components/Layout";
import colors from "@styles/colors";
import text from "@styles/text";
import media from "@styles/media";
import { StaticImage } from "gatsby-plugin-image";

import accentBG from "@png/accentBG.png";
import { ReactComponent as chevronSVG } from "@svg/chevron.svg"

import corrosion from "@jpg/miqroawareCorrosion.jpg";
import human from "@jpg/miqroawareHuman.jpg";
import component from "@jpg/miqroawareComponent.jpg";
import earth from "@jpg/miqroawareEarth.jpg";
import safety from "@jpg/miqroawareSafety.jpg";
import unknown from "@jpg/miqroawareUnknown.jpg";

const causeData = [
  {
    linkText: "Corrosion",
    heading: "Detected by Corrosion Sensors",
    text: "Our patented technology monitors for internal and external corrosion using state of the art sensors. These medical-grade sensors change the way corrosion monitoring is done. This analysis is so accurate, it can determine the rate of corroding down to 0.2mm of loss annually— less than the thickness of two human hairs.",
    image: corrosion,
  },
  {
    linkText: "Human error",
    heading:
      "Detected by Pressure, Vibration, Temperature, Audio, and Density Sensors",
    text: "Whether it is an overpressure event, overheat, wellhead issue, non-authorized dig, or even sabotage, our devices measure a variety of variables to detect the threat to your asset. Whether the error is due to operational mishaps or illegal activity, you’ll have a clear picture of what’s going on.",
    image: human,
  },
  {
    linkText: "Component failure",
    heading:
      "Detected by Pressure, Vibration, Temperature, Audio, Density Sensors",
    text: "There are a variety of reasons that pipeline components such as valves or compressor stations may fail. Weld failure, human error, and even acoustic shock can all factor into this failure. We collect data up to 42 times per second to predict these failures before they occur.",
    image: component,
  },
  {
    linkText: "Earth movement",
    heading: "Detected by Vibration, Geopositioning Sensors",
    text: "Earthquakes, land slippage, land slides, and ground settling damage pose a real threat to pipeline infrastructure. Our instant alerts allow mitigation of these risks to reduce damage and avoid disaster.",
    image: earth,
  },
  {
    linkText: "Safety system failure",
    heading: "Detected by Vibration Sensors, Supported by External Mounting",
    text: "We help keep your safety systems in check and are working toward making pigging obsolete. PHMSA data shows that hot-tapping and pigging are responsible for over 2% of pipeline leaks. By monitoring externally, we prevent these leaks and even monitor for lost pigs and leaks from competitors.",
    image: safety,
  },
  {
    linkText: "Previously unknown",
    heading:
      "Detected by Corrosion, Pressure, Vibration, Temperature, Audio, Density, & Geopositioning Sensors",
    text: "Our solution measures the most important variables to pipeline longevity. We aim to not only see known risks to pipelines, but also discover the causes of previously unknown causes of pipeline disasters so we can predict and prevent them in the future.",
    image: unknown,
  },
];

const mobileLinkOffsets = ["0%", "-5%", "-19%", "-37%", "-56%", "-69%"];

const widths = ["5.2vw", "7.8vw", "11.2vw", "10vw", "12vw", "10.4vw"];
const tabletWidths = ["7.3vw", "10vw", "15vw", "13vw", "17vw", "15.6vw"];
const mobileWidths = ["20vw", "26vw", "38.5vw", "35vw", "45vw", "41vw"];

const positions = ["0", "9.8vw", "21.48vw", "36.45vw", "50.45vw", "67vw"];
const tabletPositions = ["0", "10vw", "22.5vw", "39.6vw", "54.5vw", "73.4vw"];
const mobilePositions = ["0", "30vw", "65.6vw", "113vw", "156vw", "210vw"];

const HowItWorks: React.FC<{}> = () => {
  const [currentCause, setCurrentCause] = useState(0);

  const causeRef = useRef<HTMLDivElement>(null);
  const screen = useContext(ScreenContext)
  const handleChangeCause = (index: number) => {
    if (index === currentCause) return;
    gsap.to(causeRef.current, {
      y: "-20%",
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        setCurrentCause(index);
        gsap.fromTo(
          causeRef.current,
          { y: "20%" },
          { y: 0, opacity: 1, duration: 0.5, delay: 0.3 }
        );
      },
    });
  };

  const bind = useDrag((state) => {
    if (!screen.mobile) {
      if (state.direction[0] < 0) {
        if (currentCause < 5) {
          handleChangeCause(currentCause + 1);
        }
      }
      if (state.direction[0] > 0) {
        if (currentCause > 0) {
          handleChangeCause(currentCause - 1);
        }
      }
    }
  });

  const causeHeadings = causeData.map((cause, i) => (
    <CauseLink
      key={i}
      onClick={() => {
        handleChangeCause(i);
      }}
      active={currentCause === i}
    >
      {cause.linkText}
    </CauseLink>
  ));

  const handlePrevCause = () => handleChangeCause(Math.max(currentCause - 1, 0))

  const handleNextCause = () => handleChangeCause(Math.min(currentCause + 1, 5))

  return (
    <Wrapper {...bind()}>
      <Accent src={accentBG} alt="gradient"/>
      <HeadingSmall>
        Monitors all the leading causes of pipeline failure
      </HeadingSmall>
      <CauseLinks offset={mobileLinkOffsets[currentCause]}>
        {causeHeadings}
        <LinkLine
          lineWidth={
            screen.mobile
              ? mobileWidths[currentCause]
              : screen.tablet
              ? tabletWidths[currentCause]
              : widths[currentCause]
          }
          linePos={
            screen.mobile
              ? mobilePositions[currentCause]
              : screen.tablet
              ? tabletPositions[currentCause]
              : positions[currentCause]
          }
        />
      </CauseLinks>
      <CurrentCause ref={causeRef}>
        <TextWrapper>
          <Subheading>{causeData[currentCause].heading}</Subheading>
          <Text>{causeData[currentCause].text}</Text>
        </TextWrapper>
        <Image width={650} height={415} src={causeData[currentCause].image} alt={causeData[currentCause].linkText}/>
      </CurrentCause>
      <TouchButtons>
        <TouchPrevious onClick={handlePrevCause} disabled={currentCause === 0} />
        <TouchNext onClick={handleNextCause} disabled={currentCause === 5}/>
      </TouchButtons>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 6.6vw 9.6vw 15.9vw 13vw;
  position: relative;
  ${media.tablet} {
    padding: 10.8vw 0 17.2vw 6vw;
  }
  ${media.mobile} {
    padding: 23.5vw 6.7vw 41.6vw;
  }
`;

const Accent = styled.img`
  position: absolute;
  width: 62.5vw;
  height: auto;
  top: 6.7vw;
  left: -14.5vw;
  transform: rotate(135deg);
  opacity: 0.4;

  ${media.tablet} {
    width: 108vw;
    top: -24.7vw;
    left: -44.5vw;
  }
`;

const HeadingSmall = styled.h1`
  ${text.desktopSHeading}
  color: ${colors.culturedWhite100};
  width: 40vw;
  margin-bottom: 3vw;
  ${media.tablet} {
    ${text.tabletSHeading}
    width: 80%;
    margin-bottom: 6vw;
  }
  ${media.mobile} {
    ${text.mobileXSHeading}
    width: 100%;
    margin-bottom: 10.7vw;
  }
`;

const CauseLinks = styled.div<{ offset: string }>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.jetBlack20};
  margin-bottom: 5.6vw;
  position: relative;
  ${media.tablet} {
    width: 94%;
  }
  ${media.mobile} {
    width: 250vw;
    transition: transform 300ms ease-out;
    transform: translateX(${(props) => props.offset});
  }
`;

const CauseLink = styled.p<{ active: boolean }>`
  ${text.desktopBodyCopy1}
  color: ${(props) =>
    props.active ? colors.darkOrange100 : colors.culturedWhite100};
  padding-bottom: 0.7vw;
  margin-bottom: -1px;
  cursor: pointer;
  transition: color 300ms ease-out;
  position: relative;

  ${media.tablet} {
    ${text.tabletSmallBody}
  }
  ${media.mobile} {
    ${text.mobileBodyCopy1}/* &::after {
      content: "";
      position: absolute;
      background: ${colors.darkOrange100};
      height: 2px;
      width: ${(props) => (props.active ? "100%" : "0")};
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      transition: width 300ms ease-out;
    } */
  }
`;

const CurrentCause = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.mobile} {
    flex-direction: column-reverse;
  }
`;

const TextWrapper = styled.div`
  width: 32.7%;
  ${media.tablet} {
    width: 41.4%;
  }
  ${media.mobile} {
    width: 100%;
  }
`;

const Subheading = styled.h1`
  ${text.desktopXXSHeading}
  color: ${colors.culturedWhite100};
  margin-bottom: 2.1vw;
  ${media.tablet} {
    ${text.tabletXXSHeading}
    margin-bottom: 3.6vw;
  }
  ${media.mobile} {
    ${text.mobileXXSHeading}
    margin-bottom: 8vw;
  }
`;

const Text = styled.p`
  ${text.desktopBodyCopy1}
  color: ${colors.culturedWhite100};
  ${media.tablet} {
    ${text.tabletBodyCopy1}
  }
  ${media.mobile} {
    ${text.mobileSmallBody}
  }
`;

const Image = styled.img`
  width: 60%;
  height: auto;
  ${media.tablet} {
    width: 48.6%;
    height: 50.4vw;
    object-fit: cover;
  }
  ${media.mobile} {
    width: 100%;
    margin-bottom: 10.7vw;
  }
`;

const LinkLine = styled.div<{ lineWidth: string; linePos: string }>`
  width: ${(props) => props.lineWidth};
  height: 2px;
  background: ${colors.darkOrange100};
  position: absolute;
  bottom: -1px;
  left: ${(props) => props.linePos};
  transition: 300ms ease-out;
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const TouchButtons = styled.div`
  display: none;
  position: absolute;
  justify-content: space-between;

  ${media.mobile} {
    display: flex;
    top: 95vw;
    left: 2.5vw;
    width: 95vw;
    height: 17vw;
  }
`

const TouchNext = styled(chevronSVG)<{ disabled: boolean }>`
  background: rgba(5, 5, 5, 0.2);
  border: 1px solid ${colors.white};
  box-sizing: border-box;
  opacity: ${props => props.disabled ? '0' : '1'};
  transition: opacity 200ms ease;
  path {
    stroke: ${colors.white};
  }
  ${media.tablet} {
    height: 8vw;
    width: 8vw;
    padding-top: 0.5vw;
  }
  ${media.mobile} {
    height: 17vw;
    width: 17vw;
    padding-top: 1vw;

  }
`

const TouchPrevious = styled(TouchNext)`
  transform: rotate(180deg);
`

export default HowItWorks;
